import gsap from 'gsap';
import $ from '../core/Dom';
import shouldAnimate from '../lib/ReducedMotion';

export default el => {
    const dom = $(el);
    const list = dom.find('[data-article-listing]');
    const button = dom.find('[data-load-button]');
    const cutoff = parseInt(dom.data('cutoff'), 10);

    const reveal = () => {
        const next = list.find('[data-article][hidden]').slice(0, cutoff).attr('hidden', null);
        const first = next.eq(0).find('a');
        if (first) {
            const offset = first.offset();
            console.log(first, offset);
            gsap.to(window, {
                duration: shouldAnimate() ? 1 : 0.01,
                scrollTo: { y: offset.top, autoKill: false },
                ease: 'power2.inOut',
                onComplete: () => {
                    first.get(0).focus();
                }
            });
        }
        if (next.length < cutoff) {
            button.parent().remove();
        }
    };

    const init = () => {
        button.on('click', reveal);
    };

    const destroy = () => {
        button.off('click', reveal);
    };

    return {
        init,
        destroy
    };
};
