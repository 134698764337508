import gsap from 'gsap';
import $ from '../core/Dom';
import shouldAnimate from '../lib/ReducedMotion';

const init = el => {
    $(el).on('click', e => {
        const parent = el.parentNode;
        if (parent) {
            const rect = el.parentNode.getBoundingClientRect();
            const bottom = Math.ceil(rect.bottom + window.scrollY + 1);
            e.preventDefault();
            gsap.to(window, { duration: shouldAnimate() ? 1 : 0.01, scrollTo: { y: bottom, autoKill: false }, ease: 'power2.inOut', onComplete: () => { /*TODO*/ } });
        }
    });
};

export default {
    init
};
