import Flickity from 'flickity';
import $ from '../core/Dom';

export default el => {
    let flkty = null;
    const dom = $(el);

    const init = () => {
        flkty = new Flickity(el, {
            prevNextButtons: false,
            pageDots: true,
            wrapAround: true,
            dragThreshold: 10,
            autoPlay: 6500,
            pauseAutoPlayOnHover: false
        });

        flkty.on('dragStart', () => {
            document.ontouchmove = e => e.preventDefault();
        });

        flkty.on('dragEnd', () => {
            document.ontouchmove = () => true;
        });
    };

    const destroy = () => {
        flkty.destroy();
    };

    return {
        init,
        destroy
    };
};
