import gsap from 'gsap';
import $ from '../core/Dom';
import shouldAnimate from '../lib/ReducedMotion';

export default el => {
    const button = $(el).find('[aria-expanded]');
    const content = $(el).find('[data-content]').get(0);

    const toggle = () => {
        const status = button.attr('aria-expanded');
        button.attr('aria-expanded', status === 'false' ? 'true' : 'false');
        if (status === 'false') {
            gsap.set(content, { height: 'auto' });
            gsap.from(content, {height: 0, duration: 0.75, ease: 'power2.inOut', onComplete: () => {
                gsap.set(content, { height: 'auto' });
            }});
        } else {
            gsap.to(content, {height: 0, duration: 0.75, ease: 'power2.inOut' });
        }
    };

    const init = () => {
        button.on('click', toggle);
    };

    const destroy = () => {
        button.off('click', toggle);
    };

    return {
        init,
        destroy
    };
};
